<script>
import useVuelidate from '@vuelidate/core'
import {required, requiredIf} from '@vuelidate/validators'
import {FilterMatchMode} from 'primevue/api';

/* COMPOSANTS */
import AkFormList from "@components/layout/AkFormList";
import AkInputText from "@components/input/AkInputText";
import AkDropdown from "@components/input/AkDropdown";
import AkDialog from "@components/general/AkDialog";
import AkCheckbox from "@components/input/AkCheckbox";

/* MIXINS */
import randomRef from "@mixins/randomRef";
import utilsMixin from "@mixins/utilsMixin";
import roleMixin from "@mixins/roleMixin";
import typeVhConst from "@mixins/const/typeVhConst";

/* SERVICES */
import agerService from "@services/agerService";
import uerService from "@services/uerService";
import ceiService from "@services/ceiService";
import circuitService from "@services/circuitService";
import rolePermissionService from "@services/rolePermissionService";
import mobileMixin from "@mixins/mobileMixin";

export default {
  components: {AkFormList, AkInputText, AkDropdown, AkDialog, AkCheckbox},
  mixins: [randomRef, utilsMixin, roleMixin, typeVhConst, mobileMixin],
  metaInfo() {
    return {
      title: "circuit.list",
    }
  },
  setup() {
    return {v$: useVuelidate()}
  },
  data() {
    return {
      list: [],
      current: {},
      agerList: [],
      uerList: [],
      ceiList: [],
      loading: false,
      multiSortMeta: [
        {field: 'agerLabel', order: 1},
        {field: 'uerLabel', order: 1},
        {field: 'ceiLabel', order: 1},
        {field: 'number', order: 1}
      ],
      filters: {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS}
      },
      selectAll: false,
      selectedRows: new Set(),
      isExportDownloading: false,
      ceiId: null,
      uerId: null,
      agerId: null,
      userParams: roleMixin.userParams,
    }
  },
  validations() {
    return {
      current: {
        number: {required},
        section: {required},
        axis: {required},
        agerId: {required},
        uerId: {required},
        ceiId: {required},
        typeVh: {required: requiredIf(this.current.displayTypeVh)},
      }
    }
  },
  mounted() {
    this.loading = true;
    this.getRef().showTotalLoader();

    let p0 = rolePermissionService.authorizeAccessByPermission(this.permissionConst.admin.view);

    let p1 = agerService.findAllCurrentForAdmin();
    p1.then(data => this.agerList = data);

    let p2 = uerService.findAllCurrent();
    p2.then(data => this.uerList = data);
    this.getStoredFilterValues();

    let filter = {}
    if (this.agerId) filter.agerId = this.agerId;
    if (this.uerId) filter.uerId = this.uerId;
    if (this.ceiId) filter.ceiId = this.ceiId;

    let p4 = circuitService.findAllCurrentFilter(filter);
    p4.then(data => this.list = data);

    Promise.all([p0, p4]).then(() => {
      if (this.currentUser.ceiId != null) {
        this.agerId = this.currentUser.agerId;
        this.uerId = this.currentUser.uerId;
        this.ceiId = this.currentUser.ceiId;
      } else if (this.currentUser.uerId != null) {
        this.agerId = this.currentUser.agerId;
        this.uerId = this.currentUser.uerId;
      } else if (this.currentUser.agerId != null) {
        this.agerId = this.currentUser.agerId;
      }

      this.reloadAgerUerCeiLists().then(() => {
        this.loading = false;
        this.getRef().hideLoader();
      });
    });
  },
  methods: {
    create() {
      this.getRef().resetMessages();
      this.v$.$touch();
      if (this.v$.$error) return;

      if (!this.current.displayTypeVh) this.current.typeVh = null;

      this.getRef().showTotalLoader();
      circuitService.create(this.current).then(data => {
        this.list.push(data);
        this.current = {};

        this.getRef().success(this.$t("circuit.added"));
        this.$refs.dialogCreate.hide();
      }).finally(() => {
        this.getRef().hideLoader();
      });
    },
    update() {
      this.getRef().resetMessages();
      this.v$.$touch();
      if (this.v$.$error) return;

      if (!this.current.displayTypeVh) this.current.typeVh = null;

      this.getRef().showTotalLoader();
      circuitService.update(this.current).then(data => {
        this.replaceInListById(this.list, data);
        this.current = {};

        this.getRef().success(this.$t("circuit.updated"));
        this.$refs.dialogUpdate.hide();
      }).finally(() => {
        this.getRef().hideLoader();
      });
    },
    delete() {
      this.getRef().resetMessages();
      circuitService.delete(this.current).then(data => {
        this.removeInListById(this.list, data.id);
        this.getRef().success(this.$t("circuit.deleted"));
      }).catch(e => {
        this.getRef().error(e.response.data.error);
      });
    },
    download() {
      this.isExportDownloading = true;
      let filter = {}
      if (this.agerId) filter.agerId = this.agerId;
      if (this.uerId) filter.uerId = this.uerId;
      if (this.ceiId) filter.ceiId = this.ceiId;
      circuitService.downloadExcel(filter).then(() => {
        this.isExportDownloading = false;
      }).catch(() => {
        this.isExportDownloading = false;
      });
    },
    openCreateDialog() {
      this.current = {};
      this.$refs.dialogCreate.show();
    },
    openEditDialog(data) {
      if (data.typeVh) data.displayTypeVh = true;
      this.current = {...data};
      this.$refs.dialogUpdate.show();
    },
    openDeleteDialog(data) {
      this.current = data;
      this.$refs.dialogDelete.show();
    },
    openDeleteSelectedDialog() {
      this.$refs.dialogDeleteSelected.show();
    },
    onSelectAllChange() {
      this.selectedRows = new Set();
      if (this.selectAll) {
        this.list.forEach(circuit => {
          circuit.selected = true;
          this.selectedRows.add(circuit);
        });
      } else {
        this.list.forEach(circuit => {
          circuit.selected = false;
        });
      }
    },
    onSelectedChange(data) {
      if (!data.selected) {
        if (this.selectAll) {
          this.selectAll = false;
        }
        this.selectedRows.delete(data);
      } else {
        this.selectedRows.add(data);
      }
    },
    deleteSelectedRows() {
      if (this.selectedRows.size === 0) {
        this.getRef().error(this.$t("circuit.no_circuit_selected"));
        return;
      }
      if (this.selectedRows.length > 50) {
        this.getRef().error(this.$t("circuit.too_many_circuits_selected"));
        return;
      }
      circuitService.deleteList(Array.from(this.selectedRows)).then(() => {
        if (this.selectedRows.size > 1)
          this.getRef().success(this.$t("circuit.circuits_deleted"));
        else
          this.getRef().success(this.$t("circuit.deleted"));
        this.reloadList();
        if (this.selectAll) {
          this.selectAll = false;
          this.selectedRows = new Set();
        }
      }).catch((e) => {
        console.error(e);
        if (this.selectedRows.size > 1)
          this.getRef().error(this.$t("circuit.circuits_not_deleted"));
        else
          this.getRef().error(this.$t("circuit.deleted_failed"));
      })
    },
    reloadList() {
      this.loading = true;
      this.getRef().showTotalLoader();

      this.getStoredFilterValues();

      let filter = {}
      if (this.agerId) filter.agerId = this.agerId;
      if (this.uerId) filter.uerId = this.uerId;
      if (this.ceiId) filter.ceiId = this.ceiId;

      circuitService.findAllCurrentFilter(filter).then(data => {
        this.list = data;
      }).finally(() => {
        if (this.currentUser.ceiId != null) {
          this.agerId = this.currentUser.agerId;
          this.uerId = this.currentUser.uerId;
          this.ceiId = this.currentUser.ceiId;
        } else if (this.currentUser.uerId != null) {
          this.agerId = this.currentUser.agerId;
          this.uerId = this.currentUser.uerId;
        } else if (this.currentUser.agerId != null) {
          this.agerId = this.currentUser.agerId;
        }

        this.reloadAgerUerCeiLists().then(() => {
          this.loading = false;
          this.getRef().hideLoader();
        });
      });
    },
    getStoredFilterValues() {
      this.ceiId = this.userParams.circuit.ceiId;
      this.uerId = this.userParams.circuit.uerId;
      this.agerId = this.userParams.circuit.agerId;
    },
    resetFilter() {
      this.ceiId = null;
      this.uerId = null;
      this.agerId = null;
      this.userParams.circuit.ceiId = null;
      this.userParams.circuit.uerId = null;
      this.userParams.circuit.agerId = null;
      this.$store.dispatch('setUserParams', this.userParams);
      this.reloadList();
    },
    reloadAgerUerCeiLists() {
      let p5 = agerService.findAllCurrentForAdmin().then(data => this.agerList = data);
      let p6 = uerService.findAllCurrent().then(data => {
        if (this.agerId != null) this.uerList = data.filter(val => val.agerId === this.agerId);
        else this.uerList = data;
      });
      let p7 = ceiService.findAllCurrent().then(data => {
        if (this.agerId != null) data = data.filter(val => val.agerId === this.agerId);
        if (this.uerId != null) data = data.filter(val => val.uerId === this.uerId);
        this.ceiList = data;
      });

      return Promise.all([p5, p6, p7]);
    },
  },
  computed: {
    uerListForCurrent() {
      return this.uerList.filter(uer => uer.agerId === this.current.agerId);
    },
    ceiListForCurrent() {
      return this.ceiList.filter(cei => cei.uerId === this.current.uerId);
    },
  },
  watch: {
    uerListForCurrent() {
      if (!this.uerListForCurrent.some(uer => uer.id === this.current.uerId))
        this.current.uerId = undefined;
    },
    ceiListForCurrent() {
      if (!this.ceiListForCurrent.some(cei => cei.id === this.current.ceiId))
        this.current.ceiId = undefined;
    },
    agerId() {
      if (this.loading) return;
      this.uerId = null;
      this.ceiId = null;
      this.userParams.circuit.agerId = this.agerId;
      this.userParams.circuit.uerId = this.uerId;
      this.userParams.circuit.ceiId = this.ceiId;
      this.$store.dispatch("setUserParams", this.userParams);
      this.reloadList();
    },
    uerId() {
      if (this.loading) return;
      this.ceiId = null;
      this.userParams.circuit.uerId = this.uerId;
      this.userParams.circuit.ceiId = this.ceiId;
      this.$store.dispatch("setUserParams", this.userParams);
      this.reloadList();
    },
    ceiId() {
      if (this.loading) return;
      this.userParams.circuit.ceiId = this.ceiId;
      this.$store.dispatch("setUserParams", this.userParams);
      this.reloadList();
    },
  },
}
</script>

<template v-if="!roleLoading && this.canViewAdmin()">
  <AkFormList :ref="ref" :title="$t('circuit.list')">
    <template v-slot:action>
      <div class="action-bar" v-if="!isMobile">
        <span class="p-input-icon-left">
          <i class="pi pi-search"/>
          <InputText v-model="filters['global'].value" :placeholder="$t('search_here')"/>
        </span>
        <button v-if=this.canEditAdmin() class="btn btn-inverse-primary" @click="openCreateDialog()">
          <i class="fe fe-plus pr-1"/>
          {{ $t('add') }}
        </button>
        <Button
            v-if="this.canEditAdmin()"
            class="btn btn-inverse-danger"
            @click="openDeleteSelectedDialog()">
          <i class="fe fe-trash pr-1"/>
          {{ $t('circuit.delete_selected_circuits') }}
        </Button>
        <button @click="download()" class="btn btn-inverse-primary"
                :disabled="isExportDownloading">
          <i class="fe fe-download pr-1" v-if="!isExportDownloading"/>
          <i class="pi pi-spin pi-spinner mr-1" v-if="isExportDownloading"></i>
          {{ $t('circuit.download_excel') }}
        </button>
      </div>
    </template>
    <template v-slot:search>
      <div class="row" v-if="isMobile">
        <div class="col-lg-12">
          <div class="card card-statistics mb-30">
            <div class="card-body action-card flex-start">
              <span class="p-input-icon-left">
                <i class="pi pi-search"/>
                <InputText v-model="filters['global'].value" :placeholder="$t('search_here')"/>
              </span>
              <button v-if=this.canEditAdmin() class="btn btn-inverse-primary" @click="openCreateDialog()">
                <i class="fe fe-plus pr-1"/>
                {{ $t('add') }}
              </button>
              <Button
                  v-if="this.canEditAdmin()"
                  class="btn btn-inverse-danger"
                  @click="openDeleteSelectedDialog()">
                <i class="fe fe-trash pr-1"/>
                {{ $t('circuit.delete_selected_circuits') }}
              </Button>
              <button @click="download()" class="btn btn-inverse-primary"
                      :disabled="isExportDownloading">
                <i class="fe fe-download pr-1" v-if="!isExportDownloading"/>
                <i class="pi pi-spin pi-spinner mr-1" v-if="isExportDownloading"></i>
                {{ $t('circuit.download_excel') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:list>
      <div class="row">
        <div class="col-lg-12">
          <div class="card card-statistics">
            <div class="card-body">
              <div class="row" style="align-items: center;">
                <div style="flex: 1; display: flex;gap: 1em; align-items: center;" class=" col-md-12">
                  <AkDropdown :options="agerList"
                              v-model="agerId"
                              :show-clear=true
                              option-label="label"
                              option-value="id"
                              :label="$t('ager_label')"
                              class-name="col-md-2"/>
                  <AkDropdown :options="uerList"
                              v-model="uerId"
                              :show-clear=true
                              option-label="label"
                              option-value="id"
                              :label="$t('uer_label')"
                              class-name="col-md-2"/>
                  <AkDropdown :options="ceiList"
                              v-model="ceiId"
                              :show-clear=true
                              option-label="label"
                              option-value="id"
                              :label="$t('cei_label')"
                              class-name="col-md-2"/>
                </div>
                <button @click="resetFilter()" class="btn btn-inverse-primary mr-3">{{ $t('reset_filter') }}</button>
              </div>
              <div class="table-responsive">
                <DataTable
                    :loading="loading"
                    :paginator="false"
                    :value="list"
                    class="table"
                    :globalFilterFields="['number','section','axis','agerLabel','uerLabel','ceiLabel']"
                    v-model:filters="filters"
                    removableSort
                    sortMode="multiple"
                    :multiSortMeta="multiSortMeta"
                    responsiveLayout="scroll"
                    stateStorage="session" stateKey="dt-circuit-list"
                    stripedRows>
                  <template #empty>
                    {{ $t("list_empty") }}
                  </template>
                  <template v-if="this.canEditAdmin">
                    <Column headerStyle="width: 3rem">
                      <template #header>
                        <AkCheckbox v-model="selectAll" @change="onSelectAllChange"/>
                      </template>
                      <template #body="slotProps">
                        <AkCheckbox v-model="slotProps.data.selected" @change="onSelectedChange(slotProps.data)"/>
                      </template>
                    </Column>
                  </template>
                  <Column field="number" :header="$t('circuit.number')" :sortable="true">
                    <template #body="slotProps">
                      {{ slotProps.data.number }}
                    </template>
                  </Column>
                  <Column field="section" :header="$t('circuit.section')" :sortable="true">
                    <template #body="slotProps">
                      {{ slotProps.data.section }}
                    </template>
                  </Column>
                  <Column field="axis" :header="$t('circuit.axis')" :sortable="true">
                    <template #body="slotProps">
                      {{ slotProps.data.axis }}
                    </template>
                  </Column>
                  <Column field="agerLabel" :header="$t('ager_label')" :sortable="true">
                    <template #body="slotProps">
                      {{ slotProps.data.agerLabel }}
                    </template>
                  </Column>
                  <Column field="uerLabel" :header="$t('uer_label')" :sortable="true">
                    <template #body="slotProps">
                      {{ slotProps.data.uerLabel }}
                    </template>
                  </Column>
                  <Column field="ceiLabel" :header="$t('cei_label')" :sortable="true">
                    <template #body="slotProps">
                      {{ slotProps.data.ceiLabel }}
                    </template>
                  </Column>
                  <Column field="ceiLabel" :header="$t('type_vh')" :sortable="true">
                    <template #body="slotProps">
                      {{ displayForTypeVhConst(slotProps.data.typeVh) }}
                    </template>
                  </Column>

                  <Column bodyStyle="text-align: right; overflow: visible"
                          headerStyle=" width: 160px; text-align: center">
                    <template #body="slotProps">
                      <span v-if=this.canEditAdmin()
                            class="btn btn-xs btn-inverse-primary pointer"
                            @click="openEditDialog(slotProps.data)">
                        <i class="fe fe-edit"/></span>
                      <span v-if=this.canDeleteAdmin()
                            class="btn btn-xs btn-inverse-danger ml-1"
                            @click="openDeleteDialog(slotProps.data)">
                         <i class="fe fe-trash"/></span>
                    </template>
                  </Column>
                </DataTable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template v-slot:extra>
      <AkDialog ref="dialogCreate"
                :title="$t('circuit.create_dialog')"
                :validate-label="$t('add')"
                width="450px"
                @validate="this.create()">
        <div class="form-row">
          <AkInputText :label="$t('circuit.number')"
                       v-model="current.number"
                       :validator="v$.current.number"
                       class-name="col-12"/>
        </div>
        <div class="form-row">
          <AkInputText :label="$t('circuit.section')"
                       v-model="current.section"
                       :validator="v$.current.section"
                       class-name="col-12"/>
        </div>
        <div class="form-row">
          <AkInputText :label="$t('circuit.axis')"
                       v-model="current.axis"
                       :validator="v$.current.axis"
                       class-name="col-12"/>
        </div>
        <div class="form-row">
          <AkDropdown :label="$t('ager_label')"
                      v-model="current.agerId"
                      :validator="v$.current.agerId"
                      :options=this.agerList
                      option-value="id"
                      option-label="label"
                      class-name="col-12"/>
        </div>
        <div class="form-row">
          <AkDropdown :label="$t('uer_label')"
                      v-if="current.agerId"
                      v-model="current.uerId"
                      :validator="v$.current.uerId"
                      :options=this.uerListForCurrent
                      option-value="id"
                      option-label="label"
                      class-name="col-12"/>
        </div>
        <div class="form-row">
          <AkDropdown :label="$t('cei_label')"
                      v-if="current.uerId"
                      v-model="current.ceiId"
                      :validator="v$.current.ceiId"
                      :options=this.ceiListForCurrent
                      option-value="id"
                      option-label="label"
                      class-name="col-12"/>
        </div>
        <div class="form-row">
          <AkCheckbox :label="$t('type_vh_display_label')"
                      v-if="current.uerId"
                      v-model="current.displayTypeVh"
                      :validator="v$.current.displayTypeVh"
                      class-name="col-12"/>
        </div>
        <div class="form-row">
          <AkDropdown :label="$t('type_vh_label')"
                      v-if="current.displayTypeVh"
                      v-model="current.typeVh"
                      :validator="v$.current.typeVh"
                      :options=typeVhConst
                      option-value="value"
                      option-label="label"
                      class-name="col-12"/>
        </div>
      </AkDialog>

      <AkDialog ref="dialogUpdate"
                :title="$t('circuit.edit_dialog')"
                :validate-label="$t('update')"
                width="450px"
                @validate="this.update()">
        <div class="form-row">
          <AkInputText :label="$t('circuit.number')"
                       v-model="current.number"
                       :validator="v$.current.number"
                       class-name="col-12"/>
        </div>
        <div class="form-row">
          <AkInputText :label="$t('circuit.section')"
                       v-model="current.section"
                       :validator="v$.current.section"
                       class-name="col-12"/>
        </div>
        <div class="form-row">
          <AkInputText :label="$t('circuit.axis')"
                       v-model="current.axis"
                       :validator="v$.current.axis"
                       class-name="col-12"/>
        </div>
        <div class="form-row">
          <AkDropdown :label="$t('ager_label')"
                      v-model="current.agerId"
                      :validator="v$.current.agerId"
                      :options=this.agerList
                      option-value="id"
                      option-label="label"
                      class-name="col-12"/>
        </div>
        <div class="form-row">
          <AkDropdown :label="$t('uer_label')"
                      v-if="current.agerId"
                      v-model="current.uerId"
                      :validator="v$.current.uerId"
                      :options=this.uerListForCurrent
                      option-value="id"
                      option-label="label"
                      class-name="col-12"/>
        </div>
        <div class="form-row">
          <AkDropdown :label="$t('cei_label')"
                      v-if="current.uerId"
                      v-model="current.ceiId"
                      :validator="v$.current.ceiId"
                      :options=this.ceiListForCurrent
                      option-value="id"
                      option-label="label"
                      class-name="col-12"/>
        </div>
        <div class="form-row">
          <AkCheckbox :label="$t('type_vh_display_label')"
                      v-if="current.uerId"
                      v-model="current.displayTypeVh"
                      :validator="v$.current.displayTypeVh"
                      class-name="col-12"/>
        </div>
        <div class="form-row">
          <AkDropdown :label="$t('type_vh_label')"
                      v-if="current.displayTypeVh"
                      v-model="current.typeVh"
                      :validator="v$.current.typeVh"
                      :options=typeVhConst
                      option-value="value"
                      option-label="label"
                      class-name="col-12"/>
        </div>
      </AkDialog>

      <AkDialog ref="dialogDelete"
                :auto-hide-on-validate="true"
                :cancel-label="$t('no')"
                :title="$t('circuit.delete_dialog')"
                :validate-label="$t('yes')"
                width="450px"
                @validate="this.delete()">
        <div class="confirmation-content">
          <i class="fe fe-alert-triangle mr-1" style="font-size: 2rem"/>
          <span>{{ $t('circuit.confirm_delete') }}</span>
        </div>
      </AkDialog>

      <AkDialog ref="dialogDeleteSelected"
                :auto-hide-on-validate="true"
                :cancel-label="$t('no')"
                :title="$t('circuit.delete_selected_dialog')"
                :validate-label="$t('yes')"
                width="450px"
                @validate="this.deleteSelectedRows()">
        <div class="confirmation-content">
          <i class="fe fe-alert-triangle mr-1" style="font-size: 2rem"/>
          <span>{{ $t('circuit.confirm_delete_selected') }}</span>
        </div>
      </AkDialog>
    </template>
  </AkFormList>
</template>